import {
  patchState,
  signalStore,
  signalStoreFeature,
  withMethods,
  withState
} from '@ngrx/signals';

import { UserProfile } from '@/shared/interfaces/user-profile.interface';

type UserProfileState = {
  currentUser: UserProfile;
  userDetails: UserProfile;
};

const initialState: UserProfileState = {
  currentUser: {
    id: 0,
    username: '',
    first_name: '',
    last_name: ''
  },
  userDetails: {
    id: 0,
    username: '',
    first_name: '',
    last_name: ''
  }
};

export function withUserProfile() {
  return signalStoreFeature(
    withState<UserProfileState>(initialState),
    withMethods((store) => ({
      updateCurrentUserProfile(userProfile: UserProfile) {
        patchState(store, { currentUser: userProfile });
      },
      updateUserDetails(userProfile: UserProfile) {
        patchState(store, { userDetails: userProfile });
      }
    }))
  );
}

export const UserProfileStore = signalStore(
  { providedIn: 'root' },
  withUserProfile()
);
